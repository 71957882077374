<template>
  <div class="">
    <center-title title="额度明细"></center-title>
    <div class="row-end">
      <el-button type="primary" size="xs" class="white nr bg-primary btn-sure mb20" @click="exportConsumeRecord">
        导出明细
      </el-button>
    </div>
    <el-table :data="tableData">
      <el-table-column align="center" prop="order_sn" label="订单编号" width="200px">
      </el-table-column>
      <el-table-column align="center" prop="order_amount" label="订单金额">
      </el-table-column>
      <el-table-column align="center" prop="order_amount" label="额度支付金额">
      </el-table-column>
      <el-table-column align="center" prop="remaining_amount" label="实时额度">
      </el-table-column>
      <el-table-column align="center" prop="create_time" label="创建时间">
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { download } from '@/utils/plugin'
import CenterTitle from "../components/CenterTitle";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      tableData: [],
      formInline: {
        user: "",
        region: "",
      },
    };
  },
  computed: {
  },
  watch: {},
  methods: {
    getYueList() {
      this.$api.getYueListApi().then((res) => {
        this.tableData = res.data.list
      });
    },
    exportConsumeRecord() {
      download('/user/exportConsumeRecord', {}, `余额明细列表${new Date().getTime()}.xlsx`)
    }
  },
  created() {
    this.getYueList();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
/deep/ .el-form {
  .el-form-item__content,
  .el-input__inner {
    height: 32px;
    line-height: 32px;
    width: 240px;
    border-radius: 0;
  }

  .el-input__icon {
    line-height: 32px;
  }
}

/deep/ .el-table {
  border: $-solid-border;
  border-bottom: none;

  th {
    background-color: $-color-center;
    font-size: 16px;
    font-weight: normal;
    color: $-color-lighter;
  }

  td {
    &:nth-child(2) {
      .cell {
        padding: 0 10px;
      }
    }

    .cell {
      padding: 0px 20px;
      line-height: 1.3;
      word-break: break-all;
    }
  }

  th,
  td {
    border-right: $-solid-border;

    &:last-child {
      border-right: none;
    }
  }
}
</style>